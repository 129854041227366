
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ConfigForm from '@/components/application/config-form.vue'
import { BaseConfig } from '@/api/maintain/base-config'
import AppDefaultConfigApi, { AppDefaultConfig } from '@/api/maintain/app-default-config'
import { ApplicationType } from '@/api/maintain/application'

@Component({
  name: 'ApplicantionDefaultConfig',
  components: { ConfigForm }
})
export default class ApplicantionDefaultConfig extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  readonly id!: number;

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  readonly viewFlag!: boolean

  @Prop({
    type: Number,
    default: undefined,
    required: false
  })
  readonly appType!: number

  /** 模版名称 */
  templateName = ''

  /** 模版名称变化时 */
  changeTemplateName (templateName: string) {
    this.templateName = templateName
    this.$emit('changeTemplateName', templateName)
  }

  /** 修改配置值 */
  updateValue (id: number, value: string) {
    return AppDefaultConfigApi.updateValueById(id, value)
  }

  @Watch('appType')
  appTypeChange (newValue: ApplicationType) {
    if ([ApplicationType.NotStandardApp, ApplicationType.NotStandardWebFront].includes(newValue)) {
      (this.$refs.configForm as any).setAdvancedSetupFlag(true)
    }
  }

  /** 保存配置方法 */
  onSave (data: BaseConfig) {
    if (data.id) {
      return AppDefaultConfigApi.updateItem({ ...data, templateName: this.templateName } as AppDefaultConfig)
    }
    return AppDefaultConfigApi.insertItem({ ...data, appId: this.id, templateName: this.templateName } as AppDefaultConfig)
  }

  /** 删除方法 */
  onDelete (datas: Array<BaseConfig>) {
    return AppDefaultConfigApi.deleteItemByIds(datas.map((e) => e.id!))
  }

  /** 拉取数据方法 */
  loadFromServer () {
    return AppDefaultConfigApi.getApplicationDefaultConfigList(
      this.id, this.templateName
    )
  }
}
