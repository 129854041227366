
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { PageListData } from '@/libs/http-request'
import { ToolBarCodeExportAll } from '@/frame/share/table-panel'
import { userHasRight } from '@/libs/user-rights'
import fullscreen from '@/components/full-screen/component.vue'
/**
 * vxe-table相关的参考，请看https://xuliangzhan_admin.gitee.io/vxe-table/#/
 */

/// 表格的定义参数接口
export interface TableGridOptions{
    /// 是否显示外边框
    border?: boolean;
    /// 列头是否可升缩
    resizable?: boolean;
    /// 设置表头所有内容过长时显示为省略号
    showHeaderOverflow?: boolean;
    /// 设置所有内容过长时显示为省略号（如果是固定列建议设置该值，提升渲染速度）
    showOverflow?: boolean;
    /// 鼠标移动时，行高亮显示
    highlightHoverRow?: boolean;
    /// 保持原始值的状态
    keepSource?: boolean;

    /// 自定义行数据唯一主键的字段名
    rowId?: string;

    [key: string]: any;
}

/// 表格的默认配置
const defaultGridOptions: TableGridOptions = {
  border: true,
  resizable: true,
  showHeaderOverflow: true,
  showOverflow: true,
  highlightHoverRow: true,
  keepSource: true,
  id: 'full_edit_1',
  rowId: 'id'
}

@Component({
  name: 'tablePanelPanel',
  components: { fullscreen }
})
export default class TablePanel extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 480
  })
  height!: number

  contextHeight=0;

  isFullScreen = false

  tableHgt = '500'

    /**
     * 表格配置
     */
    @Prop({
      type: Object,
      required: false
    })
    options?: TableGridOptions

    /**
     * 列定义列表
     */
    @Prop({
      type: Array,
      required: false,
      default: () => []
    })
    columns!: Array<any>

    /**
     * 工具栏定义
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {
          button: [],
          refresh: true,
          export: true,
          exportall: true,
          print: true,
          zoom: true,
          custom: true
        }
      }
    })
    toolbar!: any

    /**
     * tree-config 定义
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    })
    treeConfig!: any

    /**
     * checkbox-config 定义
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    })
    checkboxConfig!: any

    /**
     * expand-config 定义
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    })
    expandConfig!: any

    /**
     * 打印参数
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    })
    printConfig!: any

    /**
     * 表格导出配置
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {
          // 默认选中类型
          type: 'xlsx',
          // 自定义类型
          types: ['xlsx', 'csv', 'html', 'xml', 'txt']
        }
      }
    })
    tableExport!: any

    /**
     * 表格数据
     */
    @Prop({
      type: Object,
      required: false,
      default: () => {
        return {
          items: [],
          total: 0,
          pageSize: 10
        }
      }
    })
    gridData!: PageListData<any>

    /**
     * 表格绘制配置项
     */
    gridOptions={
    }

    /**
     * 数据加载中
     */
    @Prop({
      type: Boolean,
      required: false,
      default: false
    })
    loading!: boolean

    /**
     * 当前显示的列表
     */
    get showColums () {
      return this.columns.filter(col => {
        if (!col.showrights || col.showrights.lenght === 0) return true
        return userHasRight(col.showrights)
      })
    }

    /**
     *表格的toolbar配置
     */
    get toolbarConfig () {
      return {
        slots: {
          buttons: 'toolbar_buttons', tools: 'toolbar_tools'
        },

        refresh: false,
        export: this.toolbar.export,
        print: this.toolbar.print,
        zoom: false,
        custom: this.toolbar.custom
      }
    }

    /// 当前页号
    curPage=1
    /// 当前页大小
    pageSize=10
    /// 当前行数
    total=0

    /**
     * 自动更新表格分页数据
     */
    @Watch('gridData')
    onPageDataChange (pageData: PageListData<any>) {
      this.pageSize = pageData.pageSize
      this.total = pageData.total
    }

    @Watch('height')
    onHeightChange (val: number) {
      this.tableHgt = this.height.toString()
    }

    @Watch('expandConfig')
    onExpandConfigChange (val: any) {
      this.expandConfig = val
    }

    @Watch('checkConfig')
    oncheckConfigChanged (val: any) {
      this.checkboxConfig = val
    }

    /**
     * 处理表格的配置数据
     */
    created () {
      this.gridOptions = _.cloneDeep(defaultGridOptions)

      if (this.options) {
        _.merge(this.gridOptions, this.options)
      }
      if (this.toolbar) {
        (this.gridOptions as any).toolbarConfig = this.toolbar
      }

      if (this.columns) {
        (this.gridOptions as any).columns = this.columns
      }
      const that = this
      if (!(this.gridOptions as any).ajax) {
        (this.gridOptions as any).ajax = {}
      }
      (this.gridOptions as any).ajax.query = () => {
        that.$emit('on-refresh', { curPage: 1, pageSize: that.pageSize })
      }
      this.tableHgt = this.height.toString()
    }

    /**
     * 处理页号变更
     */
    handlePageChange (data: any) {
      this.$emit('on-refresh', { curPage: data.currentPage, pageSize: data.pageSize })
    }

    /**
     * 刷新当前页
     */
    freshData () {
      this.$emit('on-refresh', { curPage: this.curPage, pageSize: this.pageSize })
    }

    /**
     * 设置当前页号
     */
    public setCurPage (page: number) {
      this.curPage = page
    }

    /**
     * 返回表格对象
     */
    public getTableObject () {
      return this.$refs.xGrid
    }

    /**
     * 处理工具栏事件
     */
    private toolbarButtonClick (event: any) {
      this.$emit('toolbarBtnClick', event.code)
    }

    /**
     * 生成导出全部的事件
     */
    private exportAllToolBarButtonClick () {
      this.toolbarButtonClick({ code: ToolBarCodeExportAll })
    }

    private taggeFullScreen () {
      (this.$refs.fullscreen as any).toggle()
    }

    /**
     * 当前组件的高度
     */
    get tablestyle () {
      return `{height:${this.height}px}`
    }

    /**
     * 屏幕切换事件
     */
    public fullscreenChange (isfull: boolean) {
      if (!isfull) {
        this.height = 10
        this.tableHgt = '500'
      } else {
        this.tableHgt = '90%'
      }
    }

    /**
     * 列表选中行
     * selection.records : Array<any>
     */
    selectionChange (selection: any) {
      this.$emit('selectionChange', selection.records)
    }
}
