
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PropType } from 'vue'
import { CloudBaseResource } from '@/api/maintain/cloud-resource/cloud-base-resource'
import StateEditor from '@/components/state-editor.vue'

@Component({
  name: 'ResourceIntroduce',
  components: { StateEditor }
})
export default class extends Vue {
  @Prop({
    type: Object as PropType<CloudBaseResource>,
    required: true
  })
  readonly cloudBaseResourceData!: CloudBaseResource

  next () {
    this.$emit('next', 1)
  }
}
