import { showModal } from '@/libs/modal-helper'
import modal from './add-app-by-cloud-modal.vue'

export default function showAddApplicationModal (imageId: number): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    showModal<number>(modal, {
      props: {
        imageId: imageId
      }
    }, true, (id: number) => {
      resolve(id)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      resolve(0)
    })
  })
}
