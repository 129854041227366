
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ApplicationDefaultConfig from '../../application-center/components/application-default-config.vue'
import { CloudBaseResource } from '@/api/maintain/cloud-resource/cloud-base-resource'
import { PropType } from 'vue'
import ApplicationApi, { Application } from '@/api/maintain/application'
import AppDefaultConfigApi, { AppDefaultConfig } from '@/api/maintain/app-default-config'
import BooleanSelector from '@/components/selector/boolean-selector.vue'
import QueryAppSelelctor from '@/components/selector/query-app-selector.vue'
import { getterUserInfo } from '@/store'

@Component({
  name: 'BasicSetting',
  components: { ApplicationDefaultConfig, Component, BooleanSelector, QueryAppSelelctor }
})
export default class extends Vue {
  @Prop({
    type: Object as PropType<CloudBaseResource>,
    required: true
  })
  readonly cloudBaseResourceData!: CloudBaseResource

  /** 当前选择器选择的应用id */
  curAppId: number | null = null
  applicationData: Application = {} as Application
  applicationDefaultConfigs: AppDefaultConfig[] = []
  templateName = ''

  /**
   * 当前编辑权限
   */
  get canOperateApp () {
    const user = getterUserInfo()
    return user.canOperateApplication
  }

  /** 模版名称变化时 */
  changeTemplateName (templateName: string) {
    this.templateName = templateName
    this.$emit('changeTemplateName', templateName)
  }

  @Watch('curAppId')
  changeCurAppId () {
    if (this.curAppId) {
      ApplicationApi.getItemById(this.curAppId).then((response) => {
        this.applicationData = response.data as Application
      })
    }
  }

  @Watch('templateName')
  onTemplateName () {
    AppDefaultConfigApi.getApplicationDefaultConfigList(
          this.applicationData.id!, this.templateName
    ).then((res) => {
      this.applicationDefaultConfigs = res.data as AppDefaultConfig[]
    })
  }

  /** 由选择器新增的应用 自动选择 */
  createApp (val: number | null) {
    if (val === 0) this.curAppId = null
    else this.curAppId = val
  }

  next () {
    if (this.curAppId) {
      this.$emit('curAppId', this.curAppId)
      this.$emit('next', 2)
    } else {
      this.$Message.info('请先选择一个应用')
    }
  }

  back () {
    this.$emit('back', 0)
  }
}
