
import deployRequestApi, { DeployRequestApi, DeployRequest, DeployResponse, DeployTaskItem, DeployRequestType } from './../api/maintain/deploy-request'
import request, { checkRequestIsSuccess, DataApiResult, requestRawResponse } from '@/libs/http-request'
import { expect } from 'chai'
import urlparser from 'urlparser'
import { RequestOption, requestPodmanFluxUrl, uploadFilePartToPodmanServer } from './podman-request'
import appInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import applicationApi, { ApplicationType } from '@/api/maintain/application'
import attachmentFileDataApi from '@/api/maintain/attachment-filedata'
import { ImageType } from '@/api/maintain/image-resource'

/// 任务类型，更新部署时间
const TYPE_UPDATE_INSTANCE_DEPLOYTIME = 'update-instance-deploytime'
/// 任务类型，更新应用打包结果
const TYPE_UPDATE_APPCOMPILE_RESULT = 'update-appcompile-result'
/// 远程文件
const TYPE_REMOTE_FILE = 'remotefile'
/// 远程oss文件
const TYPE_OSSREMOTE_FILE = 'ossfile'
/// 部署数据类型
const TYPE_DEPLOY_DATA = 'deploydata'
/// 字串指令
const ConsoleCmdPrex = '\x1b['
const ConsoleCmdSaveCurPos = ConsoleCmdPrex + 's'
const ConsoleCmdBackPrePos = ConsoleCmdPrex + 'u'

const attachmentTypePrex = 'attr:'

/// 部署类型定义
const appTypeRequestTypeLocal: Map<ApplicationType, DeployRequestType> = new Map()
appTypeRequestTypeLocal.set(ApplicationType.WebApi, 'standard-webapi-buildlocal')
appTypeRequestTypeLocal.set(ApplicationType.WebFront, 'standard-webfront-buildlocal')
appTypeRequestTypeLocal.set(ApplicationType.NotStandardApp, 'notstandard-buildlocal')
appTypeRequestTypeLocal.set(ApplicationType.NotStandardWebFront, 'notstandard-webfront-buildlocal')
appTypeRequestTypeLocal.set(ApplicationType.ImageFromSystem, 'image-deploy')
appTypeRequestTypeLocal.set(ApplicationType.ImageFromHub, 'image-deploy')

const appTypeRequestTypeRemote: Map<ApplicationType, DeployRequestType> = new Map()
appTypeRequestTypeRemote.set(ApplicationType.WebApi, 'standard-webapi-buildremote')
appTypeRequestTypeRemote.set(ApplicationType.WebFront, 'standard-webfront-buildrmote')
appTypeRequestTypeRemote.set(ApplicationType.NotStandardApp, 'notstandard-remote')
appTypeRequestTypeRemote.set(ApplicationType.NotStandardWebFront, 'notstandard-webfront-remote')
appTypeRequestTypeRemote.set(ApplicationType.ImageFromSystem, 'image-deploy')
appTypeRequestTypeRemote.set(ApplicationType.ImageFromHub, 'image-deploy')

/**
 * 部署请求服务，这是更新之后新的版本
 */
export class DeployRequestService {
  /**
   * 通过应用实例创建请求列表
   * @param appInstances 要部署的实例列表
   * @param compileInstance 用于打包的实例
   * @param appType
   */
  public createDeployData (appInstances: AppInstance[], compileInstance: AppInstance, appType: ApplicationType): DeployRequest[][] {
    const isImage = appType === ApplicationType.ImageFromHub || ApplicationType.ImageFromSystem

    const reqData: DeployRequest[][] = []
    /// 实例的部署请求
    const instanceReqs = appInstances.map((item) => {
      const buildInstance = item.id === compileInstance?.id
      if (isImage && item.imageType === ImageType.NGINX) {
        return {
          projectId: item.projectId,
          instanceId: item.id,
          applicationId: item.applicationId,
          buildContainerId: compileInstance?.id,
          imageResoureId: item.imageId,
          template: 'nginx-image-deploy'
        } as DeployRequest
      } else {
        return {
          projectId: item.projectId,
          instanceId: item.id,
          applicationId: item.applicationId,
          buildContainerId: compileInstance?.id,
          imageResoureId: item.imageId,
          template: buildInstance ? appTypeRequestTypeLocal.get(appType) : appTypeRequestTypeRemote.get(appType)
        } as DeployRequest
      }
    })

    reqData.push(instanceReqs)
    /// 如果appInstances有关联nginx，则生成这些nginx的重新部署请求
    const nginxreploy = appInstances.filter((item) => item.nginxInstanceId !== undefined && item.nginxInstanceId != null)
      .map((item) => {
        return [{
          projectId: undefined,
          instanceId: item.nginxInstanceId,
          applicationId: item.nginxApplicationId,
          buildContainerId: compileInstance.id,
          imageResoureId: undefined,
          template: 'nginx-image-deploy'
        } as DeployRequest]
      })
    reqData.push(...nginxreploy)
    return reqData
  }

  /**
     * 请求部署任务
     * @param requestList
     * @param options
     */
  public async requestDeploy (requestList: DeployRequest[], options: RequestOption) {
    options.onMessage({
      message: '准备部署数据...\r\n',
      level: 'info'
    })
    const taskList: DeployResponse[] = await this.requestCreateDeployTaskData(requestList)
    // console.log('收到的任务数据:%o', taskList)
    for (const taskItem of taskList) {
      /// 处理标准部署数据
      if (taskItem.type === TYPE_DEPLOY_DATA) {
        const taskData = await deployRequestApi.downDeployTaskData(taskItem)
        await this.uploadDataToPodmanServer(taskItem.requestUrl,
          taskData.headers[DeployRequestApi.author_head_aukey], taskData.data)
        await requestPodmanFluxUrl(taskData.headers[DeployRequestApi.exec_taskurl],
          taskData.headers[DeployRequestApi.exec_taskurl_aukey]
          , options)
      } else if (taskItem.type === TYPE_UPDATE_INSTANCE_DEPLOYTIME) {
        /// 处理更新部署实例时间
        await appInstanceApi.updateInstanceLastDeploySuccessTime(taskItem.dataSource as number)
      } else if (taskItem.type === TYPE_UPDATE_APPCOMPILE_RESULT) {
        /// 处理更新应用打包结果
        await applicationApi.updateCompileResult(taskItem.dataSource as number, taskItem.requestUrl)
      } else if (taskItem.type === TYPE_REMOTE_FILE) {
        /// 处理远程文件
        if (taskItem.dataSource.startsWith(attachmentTypePrex)) {
          await this.downAndResendAttamentFileData(taskItem, options)
        } else throw new Error('没有实现外部http文件下载')
      } else if (taskItem.type === TYPE_OSSREMOTE_FILE) {
        throw new Error('当前还没有实现oss')
      }
    }
    return '任务完成'
  }

  /**
     * 请求部署数据任务
     * @param requestList
     * @returns
     */
  private requestCreateDeployTaskData (requestList: DeployRequest[]): Promise<DeployResponse[]> {
    return new Promise<DeployResponse[]>((resolve, reject) => {
      deployRequestApi.requestDeployTaskList(requestList)
        .then((res) => {
          if (!res.data || Object.keys(res.data).length === 0) {
            reject(new Error('返回的任务列表为空'))
            return
          }
          resolve(res.data)
        }).catch((ex) => reject(ex))
    })
  }

  /**
   * 上传任务数据，并返回对应的任务批号
   * @param url
   * @param taskItems
   * @returns
   */
  private uploadDataToPodmanServer (url: string, token: string, taskItems: DeployTaskItem[]) {
    return new Promise<string>((resolve, reject) => {
      request<DataApiResult<string>>({
        url: url,
        data: taskItems,
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        }
      }, false).then((res) => {
        expect(res.data, '没有确返回任务批号').not.undefined.and.not.empty
        // 解析目标服务器的主机信息
        const parseRes = urlparser.parse(url)
        const requestUrl = `${parseRes.host.protocol}://${parseRes.host.hostname}:${parseRes.host.port}/${res.data!}`
        resolve(requestUrl)
      })
        .catch((ex) => {
          reject(ex)
        })
    })
  }

  /// 下载远程地址文件并转发，当前未实现
  async downAndResendHttpFileData (taskItem: DeployResponse, options: RequestOption) {
    const response = await requestRawResponse({
      url: taskItem.dataSource,
      method: 'GET',
      responseType: 'blob'
    }, false)
    const errorMessge = checkRequestIsSuccess(response)
    if (errorMessge) {
      throw new Error(errorMessge)
    }
  }

  /// 下载并转发文件
  async downAndResendAttamentFileData (taskItem: DeployResponse, options: RequestOption) {
    /// 文件id
    const fileId = parseInt((taskItem.dataSource as string).substring(attachmentTypePrex.length))
    expect(fileId, '任务参数错误，文件id不允许为空').not.undefined

    const partCount = await attachmentFileDataApi.getFileSize(fileId)

    /// 分片上传文件
    for (let i = 0; i < partCount; i++) {
      const data = await attachmentFileDataApi.downFilePart(fileId, taskItem.serverId, taskItem.requestUrl, i)
      if (i === 0) {
        options.onMessage({
          level: 'info',
          message: `准备上传文件'${taskItem.podManPath}'\r\n`
        })
      }

      /// 转发数据
      await uploadFilePartToPodmanServer(
        taskItem.requestUrl, data.headers[DeployRequestApi.author_head_aukey],
        {
          path: taskItem.podManPath,
          startPos: i * attachmentFileDataApi.partSize,
          length: data.data.size
        },
        data.data
      )

      options.onMessage({
        level: 'info',
        message: `${i === 0 ? ConsoleCmdSaveCurPos : ConsoleCmdBackPrePos}上传文件'${taskItem.podManPath}'完成${Math.round(((i + 1) / partCount) * 100)}%`
      })
    }

    options.onMessage({
      level: 'info',
      message: '\r\n'
    })
  }
}

export default new DeployRequestService()
