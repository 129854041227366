/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Stomp from 'stompjs'

/**
 * rabbitmq数据访问工具
 */

export default class RabbitmqTool {
  // 基础信息
  private host: string;
  private port: number;
  private userName: string;
  private pwd: string;

  // 客户端实例
  private client?: Stomp.Client;

  // 是否自动重连
  private reconnect: boolean;
  // 自动重连计数
  readonly RECONNECT_MAX_COUNT = 3
  // 是否打印调试信息
  private debug: boolean;

  // 连接已创建过连接
  private isConnected = false
  // 最新的订阅地址
  private subscribeUrl?: string;
  // 最新的回调函数
  private lastCallback?: (data: any) => void;

  /**
   * 构造函数
   * @param {boolean} reconnect 是否自动重连
   * @param {boolean} debug 是否输出调试信息
   */
  constructor (reconnect = true, debug = process.env.NODE_ENV === 'development') {
    this.host = process.env.VUE_APP_Rabbitmq_HostName!
    this.port = parseInt(process.env.VUE_APP_Rabbitmq_Port!)
    this.userName = process.env.VUE_APP_Rabbitmq_User!
    this.pwd = process.env.VUE_APP_Rabbitmq_Pwd!
    this.reconnect = reconnect
    this.debug = debug
  }

  /**
   * 初始化
   * @param {string} subscribeUrl 要监听的地址，/exchange/exchange名称/要监听的主题,支持 #通配符 例如要监听a下的所有为a.#
   * @param {Function} onMessage 消息接收回调
   * @param {number} reconnectCount 自动重连计数,不要主动传参
   * @returns
   */
  public init (
    subscribeUrl: string,
    onMessage: (data: any) => void,
    reconnectCount = 0
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const head = process.env.VUE_APP_Rabbitmq_UseSSL === 'true' ? 'wss' : 'ws'
      this.client = Stomp.client(`${head}://${this.host}:${this.port}/ws`)
      this.client.debug = this.debug ? (str: string) => console.log(str) : () => ({})
      this.client.connect(
        {
          login: this.userName,
          passcode: this.pwd,
          // 虚拟主机，默认“/”
          host: '/'
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (frame?: Stomp.Frame) => {
          console.log('stomp服务器已经联结成功,订阅数据...')
          // 更新订阅地址
          this.isConnected = true
          this.subscribeUrl = subscribeUrl
          this.lastCallback = onMessage
          this.client!.subscribe(subscribeUrl, (message: Stomp.Message) => {
            if (onMessage && message) {
              const data = JSON.parse(message.body)
              onMessage(data)
            }
          })
          resolve()
        },
        (error: string | Stomp.Frame) => {
          reject(new Error('联结stomp服务器失败:' + error))
          if (this.reconnect && reconnectCount < this.RECONNECT_MAX_COUNT) {
            console.log(`联结stomp服务器失败，5秒后尝试重连(${reconnectCount}/${this.RECONNECT_MAX_COUNT})`)
            setTimeout(() => this.init(subscribeUrl, onMessage, ++reconnectCount), 5000)
          }
        }
      )
    })
  }

  /**
   * 关闭联结
   * @returns
   */
  public disconnection (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.client) {
        this.client.disconnect(() => {
          console.log('联结断开')
          this.client = undefined
          resolve()
        })
      }
      reject(new Error('当前联结没有初始化'))
    }).catch((err) => {
      console.log('断开失败:' + err.message)
    })
  }

  /**
   * 恢复连接
   */
  public restoreConnect (): Promise<void> {
    if (!this.isConnected) {
      return Promise.resolve()
    } else if (!this.subscribeUrl || !this.lastCallback) {
      return Promise.reject(new Error('恢复连接失败:没有订阅地址或者回调函数'))
    }
    return this.init(this.subscribeUrl, this.lastCallback)
  }
}
