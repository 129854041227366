import DefaultApiService, { BaseHasCreatorAndTimeEntity } from '@/libs/api-request'

export interface CloudBaseResource extends BaseHasCreatorAndTimeEntity {
  /** cloud资源名称 */
  name: string;
  /** 资源简介 */
  intro?: string;
  /** 镜像资源id */
  imageSourceId: number;
  /** 镜像资源名称，image_resources的image_name */
  imageSourceName: string;
  /** 资源描述 */
  resourceDest?: string;
  /** 资源图片 */
  resourceImage?: string;
  /** 后续工作地址：前端页面的路由地址 */
  extJobUri?: string;
}

/**
 * 企业微服务基础资源api接口
 */
class CloudBaseResourceApi extends DefaultApiService<CloudBaseResource> {
  constructor () {
    super('/api/maintain/cloud-base-resource')
  }

  /**
   *
   * @param id 更新步骤
   * @param step
   * @returns
   */
  updateStep (id: number, step: number) {
    return this.request(`step/${id}/${step}`, null, 'POST')
  }
}

export default new CloudBaseResourceApi()
