
import { Vue, Component, Prop } from 'vue-property-decorator'
import DeployPage from '@/components/deploy-page.vue'

@Component({
  name: 'DeployInstance',
  components: { DeployPage }
})
export default class AppBatchDeployPage extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  readonly curAppId!: number

  next () {
    this.$emit('next', 3)
  }

  back () {
    this.$emit('back', 1)
  }
}
