
import { Component, Vue } from 'vue-property-decorator'
import ResourceIntroduce from './resource-introduce.vue'
import BasicSetting from './basic-setting.vue'
import DeployInstance from './deploy-instance.vue'
import FollowUpWork from './follow-up-work.vue'
import CloudBaseResourceApi, { CloudBaseResource } from '@/api/maintain/cloud-resource/cloud-base-resource'

@Component({
  name: 'CloundGuideIndex',
  components: { ResourceIntroduce, BasicSetting, DeployInstance, FollowUpWork }
})
export default class extends Vue {
  list = ['ResourceIntroduce', 'BasicSetting', 'DeployInstance', 'FollowUpWork']
  /** 当前步骤 */
  current = this.list[0]
  cloudBaseResourceData = {} as CloudBaseResource
  /** 当前向导第二步骤时选择的应用id */
  curAppId = 0
  params={}

  created () {
    CloudBaseResourceApi.getItemById(parseInt(this.$route.params.id)).then((response) => {
      this.cloudBaseResourceData = response.data as CloudBaseResource
    })
  }

  changeCurAppId (val: number) {
    this.curAppId = val
  }

  next (val: number, params?: any) {
    this.current = this.list[val]
    this.params = params || {}
    ;((this.$refs.tab as Vue).$el as Element).parentElement!.scrollTo(0, 0)
  }

  back (val: number) {
    this.current = this.list[val]
    ;((this.$refs.tab as Vue).$el as Element).parentElement!.scrollTo(0, 0)
  }
}
