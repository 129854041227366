import { render, staticRenderFns } from "./app-batch-deploy-table-panel.vue?vue&type=template&id=12ac8070&scoped=true&"
import script from "./app-batch-deploy-table-panel.vue?vue&type=script&lang=ts&"
export * from "./app-batch-deploy-table-panel.vue?vue&type=script&lang=ts&"
import style0 from "./app-batch-deploy-table-panel.vue?vue&type=style&index=0&id=12ac8070&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ac8070",
  null
  
)

export default component.exports