
import { VModel, Vue, Component, Watch } from 'vue-property-decorator'
import {
  WithRightAndStateComponentMinx,
  WithModelComponentMinx
} from '@/libs/form-state'

/**
 * 带有状态的处理的markdown编辑器
 */
@Component({
  name: 'StateEditor',
  mixins: [WithRightAndStateComponentMinx, WithModelComponentMinx]
})
export default class StateFormItem extends Vue {
  @VModel({ type: String })
  inputContent!: string

  // 中转变量
  editorContent = ''

  @Watch('inputContent')
  onInputContentChange (newValue: string) {
    this.editorContent = newValue ?? ''
  }

  @Watch('editorContent')
  onEditorContentChange (newValue: string) {
    this.inputContent = newValue ?? ''
  }
}
