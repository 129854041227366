
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Form } from 'view-design'
import ApplicationApi, { CreateNewImageAppRequest } from '@/api/maintain/application'
import ImagesResourceApi, { ImagesResource } from '@/api/maintain/image-resource'
import TeamResourceSelector from '@/components/common-select/team-resource-selector.vue'

@Component({
  name: 'applicationAddModal',
  components: { TeamResourceSelector }
})
export default class ApplicationAddModal extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  readonly imageId!: number;

  isShow = false;

  // 镜像应用表单
  addImageFormData: CreateNewImageAppRequest = {};
  // 镜像资源options
  imageList: ImagesResource[] = [];
  // tag标签
  tags: string[] = [];
  // tag内容
  tag = '';

  /** 要显示的tag列表 */
  get showTags () {
    if (this.tags.length > 0) {
      return this.tags
    }
    return ['暂未设置标签']
  }

  ImageFormRules = {
    appName: [{ required: true, message: '请填写应用名称', trigger: 'blur' }, {
      pattern: /^[a-zA-Z][A-Za-z0-9_-]*$/,
      trigger: 'blur',
      message: '请输入英文字母与数字或下划线的组合，必须英文字母开头'
    }],
    imageId: [
      { type: 'number', required: true, message: '请选择镜像资源', trigger: 'blur' }
    ],
    imageName: [{ required: true, message: '请填写镜像名称', trigger: 'blur' }],
    appTitle: [{ required: true, message: '请填写镜像标题', trigger: 'blur' }],
    teamResourceId: [{ type: 'number', required: true, message: '请选择归属资源', trigger: 'change' }]
  };

  loading?: boolean = false;

  /** 镜像选择器加载状态 */
  selectLoading = false;

  doCancel () {
    this.$emit('onCancel')
  }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  addTags () {
    if (this.tag) {
      this.tags.push(this.tag)
      this.tag = ''
    }
  }

  closeTag (index: number) {
    this.tags.splice(index, 1)
  }

  created () {
    this.initOptions()
    this.addImageFormData.imageId = this.imageId
  }

  /** 初始化镜像资源选项 */
  initOptions () {
    ImagesResourceApi.query({
      page: 1,
      pageSize: 10,
      sorts: []
    }).then((res) => {
      this.imageList = res.data?.items || []
    })
  }

  /** 加载镜像资源 */
  loadImageList (imageTitle: string) {
    ImagesResourceApi.queryByName(imageTitle).then((response) => {
      this.imageList = response.data || []
    })
  }

  /**
   * 保存提交应用
   */
  submit () {
    const checkform = this.$refs.addImageForm as Form
    checkform.validate((valid) => {
      if (valid) {
        this.loading = true
        this.saveAppData()
      }
    })
  }

  /** 保存app信息 */
  saveAppData () {
    ApplicationApi.addNewImageApp(this.addImageFormData)
      .then((res) => {
        this.$Notice.success({
          desc: '添加应用成功！'
        })
        this.$emit('onOk', res.data?.id)
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '新增应用失败:' + err.message
        })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
