import request, { DataApiResult, ApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
/**
 * 服务器
 */

import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'
import { getterUserInfo } from '@/store'
import { downfile } from '@/libs/request'

/**
 * 服务器接口
 */
export interface PcServer extends BaseHasTimeEntity {

  // 服务器名称
  name?: string;

  // 服务器ip地址 内网
  ipAddress?: string;

  /** 管理端口 默认10001 */
  mangerPort: number;

  /** https管理端口 */
  httpsManagerPort: number;

  /** 内存 */
  serverMemory?: number|null;

  /** CPU */
  serverCpu?: number|null;

  /** 存贮 */
  serverStorage?: number;

  /** 存储单位 */
  serverStorageUnit?: string;

  /** 操作系统 */
  serverSystem?: string;

  /** 系统版本 */
  serverSystemVersion?: string;

  /** 备注 */
  memo?: string;

  /** 服务器uuid */
  serverUuid?: string;

  /** 团队id */
  teamId?: number;

  /** 服务器状态中文 */
  serverStatusText?: string;

  /** 服务器状态英文 未知-unknown 在线-online 离线-offline */
  serverStatusEn?: string;

  /** 服务器状态更新的最近时间戳 */
  serverStatusTimestamp?: number;

  // 服务器证书开始时间，如果为空则表示还没有签发"
  certStartTime?: string;

  // 服务器证书结束时间，如果为空则表示还没有签发
  certEndTime?: string;

  // 服务器证书是否过期
  certTimeExpired?: boolean;

  /**
   * 密匙更新时间
   */
  privateKeyUpdateTime?: string;

  /** 当前用户可以操作 */
  canOperation?: boolean;

  /** 当前用户可以查看 */
  canView?: boolean;

  /** 归属资源id */
  teamResourceId?: number;
}

/**
 * 团队服务请求api接口
 */
class PcServerApi extends DefaultApiService<PcServer> {
  constructor () {
    super('/api/maintain/server-center')
  }

  /**
    * 删除id指定的数据
    * @param id 记录主键
    */
  public deleteItemById (id: number): Promise<ApiResult> {
    return this.request(`deleteById/${id}`, undefined, 'DELETE')
  }

  /**
   * 初始化服务器并返回对应的配置key
   * @param teamId
   * @param pcServerId
   */
  public initPodmanServiceAtPcServer (pcServerId: number) {
    return request<DataApiResult<string>>(
      {
        url: `${this.baseUrlPath}initPodmanServiceAtPcServer`,
        data: {
          teamId: getterUserInfo().activedTeamId,
          pcServerId: pcServerId
        },
        method: 'PUT'
      })
  }

  /** 查询所有服务器 */
  public getAllPcServers () {
    return request<DataApiResult<PcServer[]>>({
      url: `${this.baseUrlPath}queryAll`,
      method: 'POST'
    })
  }

  /** 查询第一台服务器id */
  public getFirstPcServerId () {
    return request<DataApiResult<number>>({
      url: `${this.baseUrlPath}firstPcServerId`,
      method: 'GET'
    })
  }
}

export default new PcServerApi()
