
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import TerminalVue from '@/components/xterm-vue/XtermVue.vue'
import terminalConfig from '@/views/maintain/logger-center/log-console/terminal.config'
import ApplicationApi, { Application, ApplicationType } from '@/api/maintain/application'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import appInstanceManger from '@/api/maintain/app-instance-manger'
import TablePanel from '@/views/maintain/application-center/components/app-batch-deploy-table-panel.vue'
import ElevatorRabbitmq from '@/mixins/elevator-rabbitmq'
import {
  ApplicationWebSocket
} from '@/libs/application-websocket'
import { appInstanceStatus } from '@/views/maintain/application-center/moniting-appintace-helper'
import { getShortUuid } from '@/libs/uuid'
import DeployWindowPage from '@/components/xterm-vue/deploy-window-page.vue'
import { getterUserInfo } from '@/store'
import moment from 'moment'
import PcServerApi from '@/api/maintain/pc-server'

@Component({
  name: 'DeployPage',
  components: { TerminalVue, TablePanel, DeployWindowPage },
  mixins: [ElevatorRabbitmq]
})
export default class DeployPage extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  applicationId!: number

  application: Application = {} as Application

  /** 校验中的实例 */
  checkInstance: AppInstance={} as AppInstance

  // 应用的实例集合
  instanceList?: Array<AppInstance> = [];

  // 勾选要去部署的实例集合
  selectToDeployInstanceList?: Array<AppInstance> = [];

  loading = false;

  showModal=false;

  checkIndex=0

  /// 终端配置
  terminalOption = terminalConfig.terminalOption;

  firstPcServerId=0

  /**
   * 是否为前端项目
   */
  get isWebFrontApp () {
    if (this.instanceList && this.instanceList.length > 0) {
      if ([ApplicationType.WebFront, ApplicationType.NotStandardWebFront].includes(this.instanceList[0].applicationType)) {
        return true
      }
    }
    return false
  }

  /// 选择实例id列表
  get selectInstanceIds () {
    return this.selectToDeployInstanceList?.map((e) => e.id) || []
  }

  /**
   * 更新控制台大小
   */
  resizeXterm () {
    (this.$refs.deployWindowPage as any).resizeXterm()
  }

  /**
   * 是否开通企业用户
   */
  get opening () {
    const user = getterUserInfo()
    return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
  }

  /**
   * websock实例列表
   * @key 服务器id
   * @value websock实例
   */
  websocketInstance: Record<number, ApplicationWebSocket> = {};

  /**
   * 关闭所有ws链接
   */
  closeAllWebsocket () {
    Object.values(this.websocketInstance).forEach((ws) => {
      ws.close()
    })
    this.websocketInstance = {}
  }

  /** 部署错误 */
  deployError () {
    this.loading = false
  }

  /** 新增实例 */
  addInstance () {
    let path = '/maintain/instance-page/new'
    if (this.application.appType === 2 || this.application.appType === 6) {
      path = '/maintain/webfront-page/new'
    }
    this.$router.push({
      path: path,
      query: {
        appId: this.application.id?.toString(),
        appName: this.application.name?.toString(),
        appType: this.application.appType?.toString(),
        appTypeName: this.application.appTypeName,
        defaultConfigAlerts: this.application.defaultConfigAlerts,
        defaultConfigFile: this.application.defaultConfigFile,
        imageType: this.application.imageType?.toString(),
        key: getShortUuid()
      }
    })
  }

  /** 修改实例 */
  editInstance (instanceId?: number) {
    if (!instanceId) {
      return
    }
    if (this.application.appType === 2 || this.application.appType === 6) {
      this.$router.push(`/maintain/webfront-page/${instanceId}`)
    } else {
      this.$router.push(`/maintain/instance-page/${instanceId}`)
    }
  }

  beforeDestroy () {
    this.closeAllWebsocket()
  }

  created () {
    PcServerApi.getFirstPcServerId().then((res) => {
      this.firstPcServerId = res.data || 0
    })
    if (this.applicationId) { this.loadDataFromServer() }
  }

  @Watch('applicationId')
  onApplicationId () {
    if (this.applicationId) { this.loadDataFromServer() }
  }

  /**
   * 加载数据
   */
  loadDataFromServer () {
    // 获取应用记录
    ApplicationApi.getItemById(this.applicationId)
      .then((response) => {
        const data = response.data
        this.application = data as Application
        this.listAppInstance()
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '获取应用信息失败:' + err.message
        })
      })
  }

  /**
   * 获取应用实例集合
   */
  listAppInstance () {
    AppInstanceApi.listAppInstanceByAppId(this.applicationId)
      .then((response) => {
        response.data?.forEach((e) => { e.status = 0 })
        const data = response.data
        if (this.isWebFrontApp || this.opening) {
          this.instanceList = data
        } else {
          this.instanceList = data?.filter((e) => e.pcServerId === this.firstPcServerId)
        }
        this.$nextTick(() => {
          this.resizeXterm()
        })

        // 连接ws
        if (this.instanceList && this.instanceList.length > 0) {
          if (![ApplicationType.WebFront, ApplicationType.NotStandardWebFront].includes(this.instanceList[0].applicationType)) {
            this.instanceList?.forEach((e) => { e.status = 0 })
            this.closeAllWebsocket()
            this.subscribeServersStatus(this.instanceList ? this.instanceList : [])
          }
        }
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '获取应用的实例集合失败:' + err.message
        })
      })
  }

  /// 是否全选
  get allSelect () {
    if (!this.selectToDeployInstanceList || this.selectToDeployInstanceList.length < 1) {
      return false
    }
    return this.selectToDeployInstanceList?.length === this.instanceList?.length
  }

  /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
  onSelectionChange (rows: Array<number>) {
    this.selectToDeployInstanceList = this.instanceList?.filter(
      (e) => e.id && rows.includes(e.id)
    )
  }

  /// 改变全选状态
  changeAllSelected (val: boolean) {
    if (val) {
      this.selectToDeployInstanceList = this.instanceList
    } else {
      this.selectToDeployInstanceList = []
    }
  }

  /** 模态框确认事件 */
  onOk () {
    this.checkIndex++
    if (this.selectToDeployInstanceList) {
      appInstanceManger.deleteContainerData(this.selectToDeployInstanceList[this.checkIndex]!.id!).then(async () => {
        this.showModal = false
        if (this.checkIndex < this.selectToDeployInstanceList!.length) {
          for (let i = this.checkIndex; i < this.selectToDeployInstanceList!.length; i++) {
            const res = await appInstanceManger.checkContainerData(this.selectToDeployInstanceList![i].id!)
            if (res.data.data) {
              this.showModal = true
              break
            }
            this.checkIndex++
          }
          if (this.checkIndex >= this.selectToDeployInstanceList!.length) {
            this.loading = true
            ;(this.$refs.deployWindowPage as any).beginDeploy()
            this.checkIndex = 0
          }
        } else {
          this.loading = true
          ;(this.$refs.deployWindowPage as any).beginDeploy()
          this.checkIndex = 0
        }
      }).catch((err) => {
        this.$Message.error(err.message)
      })
    }
  }

  /** 模态框取消事件 */
  async onCancel () {
    this.showModal = false
    this.checkIndex++
    if (this.checkIndex < this.selectToDeployInstanceList!.length) {
      for (let i = this.checkIndex; i < this.selectToDeployInstanceList!.length; i++) {
        const res = await appInstanceManger.checkContainerData(this.selectToDeployInstanceList![i].id!)
        if (res.data.data) {
          this.showModal = true
          break
        }
        this.checkIndex++
      }
      if (this.checkIndex >= this.selectToDeployInstanceList!.length) {
        this.loading = true
        ;(this.$refs.deployWindowPage as any).beginDeploy()
        this.checkIndex = 0
      }
    } else {
      this.loading = true
      ;(this.$refs.deployWindowPage as any).beginDeploy()
    }
  }

  // 开始部署
  async beginDeploy () {
    if (!this.instanceList || this.instanceList.length <= 0) {
      this.$Message.warning({
        content: '当前应用还未创建实例，请先创建实例再进行部署',
        duration: 5,
        closable: true
      })
      return
    }
    if ([ApplicationType.WebFront, ApplicationType.NotStandardWebFront].includes(this.application.appType!)) {
      this.loading = true
      ;(this.$refs.deployWindowPage as any).beginDeploy()
      return
    }
    for (let i = this.checkIndex; i < this.selectToDeployInstanceList!.length; i++) {
      const res = await appInstanceManger.checkContainerData(this.selectToDeployInstanceList![i].id!)
      if (res.data.data) {
        this.showModal = true
        this.checkInstance = this.selectToDeployInstanceList![this.checkIndex]
        break
      }
      this.checkIndex++
    }
    if (this.checkIndex >= this.selectToDeployInstanceList!.length) {
      this.loading = true
      ;(this.$refs.deployWindowPage as any).beginDeploy()
      this.checkIndex = 0
    }
  }

  /// 根据返回的服务器列表，订阅mq
  /// 其他用法详见 src/mixins/elevator-rabbitmq.ts
  /// 连接函数，不需要手动调用断开，切换监听条件重新connent即可
  subscribeServersStatus (items: Array<AppInstance>) {
    const that = this as any
    items.flatMap((item) => {
      if (this.websocketInstance[item.pcServerId!]) {
        return
      }
      if (!item.pcServerId) {
        return
      }
      appInstanceManger.getInstanceStatusMonitorWebSocket(item.pcServerId, location.protocol === 'https:' ? item.httpsManagerPort : item.mangerPort, {
        fixed: 2000,
        callback: (data) => {
          const dataList = that.instanceList?.filter((e: AppInstance) => e.pcServerId === item.pcServerId)
          dataList.forEach((instance: AppInstance) => {
            let containerStatus: number | undefined =
                  data?.[instance.containerName!] ? appInstanceStatus.Runing : appInstanceStatus.Stop
            if (data[instance.containerName!] === undefined) {
              containerStatus = appInstanceStatus.NodeDeploy
            }
            if (containerStatus) {
              that.$nextTick(() => {
                this.$set(instance, 'status', containerStatus)
              })
            }
          })
        },
        onError: () => {
          const dataList = that.instanceList?.filter((e: AppInstance) => e.pcServerId === item.pcServerId)
          dataList.forEach((instance: AppInstance) => {
            instance.status = appInstanceStatus.ConntionError
          })
        }
      }).then((ws) => {
        // 保存连接
        this.websocketInstance[item.id!] = ws
        /** 若10s后状态未变更则置为联结失败 */
        setTimeout(() => {
          const dataList = that.instanceList?.filter((e: AppInstance) => e.pcServerId === item.pcServerId)
          dataList.forEach((instance: AppInstance) => {
            instance.status = appInstanceStatus.ConntionError
          })
        }, 10000)
      }).catch((err) => {
        const dataList = that.instanceList?.filter((e: AppInstance) => e.pcServerId === item.pcServerId)
        dataList.forEach((instance: AppInstance) => {
          instance.status = appInstanceStatus.ConntionError
        })
        console.log(err)
      })
    })
  }

  /**
   * 实例状态文字转换
   */
  getInstanceStatusText (status?: number) {
    switch (status) {
      case 0: return '待联结'
      case 2: return '已停止'
      case 3: return '运行中'
      case 4: return '未部署'
      case 5: return '联结错误'
      default: return '未知'
    }
  }

  /**
   * 实例状态样式转换
   */
  getInstanceStatusStyle (status?: number) {
    switch (status) {
      case 0:
        return 'color:#2add9c;font-weight:700;font-size:12px'
      case 2:
        return 'color:#ffcc00;font-weight:700;font-size:12px'
      case 3:
        return 'color:#ffffff;font-weight:700;font-size:12px'
      case 4:
        return 'color:#000000;font-weight:700;font-size:12px'
      case 5:
        return 'color:#000000;font-weight:700;font-size:12px'
      default:
        return 'color:#000000;font-weight:700:font-size:12px'
    }
  }

  /// 根据实例状态获取tag颜色
  getTagColor (status?: number) {
    switch (status) {
      case 0:
        return 'primary'
      case 2:
        return 'warning'
      case 3:
        return 'success'
      case 4:
        return 'default'
      case 5:
        return 'error'
      default:
        return 'default'
    }
  }
}
