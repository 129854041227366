import DefaultApiService, { BaseHasCreatorAndTimeEntity } from '@/libs/api-request'
import request, { DataApiResult } from '@/libs/http-request'
import { TeamResourceApp } from './team-resource-app'
import { TeamResourcePcServer } from './team-resource-pc-server'

/** 团队资源 */
export interface TeamResource extends BaseHasCreatorAndTimeEntity {
  /** id */
  id: number;
  /** 资源名称 */
  name: string;
  /** 资源说明 */
  memo: string;
  /** 归属团队 */
  teamId: number;
  /** 归属团队名称 */
  teamName: string;
}

/** 新增团队资源vo */
export interface NewTeamResourceVo {
  /** 新增团队资源 */
  teamResource: TeamResource;
  /** 新增服务器列表 */
  pcServerList: TeamResourcePcServer[];
  /** 新增应用列表 */
  appList: TeamResourceApp[];
}

/** 团队资源Api接口 */
class TeamResourceApi extends DefaultApiService<TeamResource> {
  constructor () {
    super('/api/maintain/team-resource')
  }

  /** 新增 */
  public newInsertItem (data: NewTeamResourceVo): Promise<DataApiResult<TeamResource>> {
    return request<DataApiResult<TeamResource>>({
      url: this.baseUrlPath + 'newInsertItem',
      method: 'PUT',
      data: data
    })
  }

  /** 查询可添加应用实例 */
  public queryCanAddAppItems () {
    return request<DataApiResult<TeamResource[]>>({
      url: this.baseUrlPath + 'queryCanAddAppItems',
      method: 'POST'
    })
  }
}

export default new TeamResourceApi()
