/// 应用实例的状态
export const appInstanceStatus = {
  /// 待联结
  PendConntion: 0,
  /// 停用
  Stop: 2,
  /// 运行中
  Runing: 3,
  /// 没有部署
  NodeDeploy: 4,
  /// 联结错误
  ConntionError: 5
}

/// 在监控的实例
export interface MonitingAppIntance{
    /// 要监控的实例id
    appInstanceId: number;
    /// 实例名称
    appInstanceName: string;
    /// 要监控的应用id
    appId: number;
    /// 当前的状态
    status: number;
}

/// 在监控的服务器集合
export type MonitingPcCollection=Record<number, MonitingAppIntance[]>

export interface AppIntaceAtPc{
    /// 应用实例id
    appInstanceId: number;
    /// 实例名称
    appInstanceName: string;
    /// 数据库实例状态
    statusAtDB: number;
    /// pcid
    pcId: number;
    /// pcIp
    pcIpAddress: string;
    // pcPort
    pcPort: number;
}
