
import { AppInstance } from '@/api/maintain/app-instance'
import { DeployRequest } from '@/api/maintain/deploy-request'
import showSelectComplieInstanceModal from '@/views/maintain/application-center/components/modal/show-select-complie-instance-modal'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TerminalVue, { XtermVueInterface } from './XtermVue.vue'
import deployRequestService from '@/libs/deploy-request-service'
import { RequestOption } from '@/libs/podman-request'
import { ApplicationType } from '@/api/maintain/application'

@Component({
  name: 'DeployWindowPage',
  components: { TerminalVue }
})
export default class DeployWindowPage extends Vue {
  /** 控制面板配置 */
  @Prop({
    type: Object,
    default: () => ({}),
    required: false
  })
  public terminalOption!: Record<string, any>;

  /**
   * 部署实例列表
   */
  @Prop({
    type: Array,
    default: () => [],
    required: false
  })
  readonly instanceList!: Array<AppInstance>;

  /**
   * 部署请求配置
   */
  @Prop({
    type: Object,
    default: () => ({}),
    required: false
  })
  public options!: RequestOption;

  /**
   * 部署完成事件
   */
  @Prop({
    type: Function,
    required: false
  })
  readonly onDeployFinish!: () => void;

  /**
   * 错误处理
   */
  @Prop({
    type: Function,
    required: false
  })
  readonly onDeployError!: (err: Error) => void;

  xterm!: XtermVueInterface;

  /**
   * 面板准备完成事件
   */
  onTerminalReady () {
    this.xterm = (this.$refs.term as any) as XtermVueInterface
  }

  /**
   * 更新控制台大小
   */
  resizeXterm () {
    (this.$refs.term as any).fitAddon?.fit()
  }

  // 开始部署
  async beginDeploy () {
    if (!this.instanceList || this.instanceList.length <= 0) {
      this.$Message.warning({
        content: '请勾选需要部署的实例',
        duration: 5,
        closable: true
      })
      this.$emit('exitDeploy')
      return
    }
    let execCompileInstance: AppInstance | undefined = this.instanceList[0]
    if (![ApplicationType.ImageFromHub, ApplicationType.ImageFromSystem].includes(this.instanceList[0].applicationType) && this.instanceList.length > 1) {
      showSelectComplieInstanceModal(this.instanceList).then(async (res) => {
        execCompileInstance = this.instanceList?.find((e) => e.id === res)
        if (!execCompileInstance) {
          this.$Message.warning({
            content: '请选择进行编译实例服务器',
            duration: 5,
            closable: true
          })
          this.$emit('exitDeploy')
        } else {
          this.performDeploy(execCompileInstance)
        }
      }).catch(() => {
        this.$emit('exitDeploy')
      })
    } else {
      this.performDeploy(execCompileInstance)
    }
  }

  /**
   * 执行部署
   */
  async performDeploy (execCompileInstance: AppInstance) {
    /// 获取部署请求数据
    const requestDeployData = deployRequestService.createDeployData(
      this.instanceList,
      execCompileInstance,
      this.instanceList[0].applicationType
    )
    if (requestDeployData) {
      for (let i = 0; i < requestDeployData.length; i++) {
        await this.processDeploy(requestDeployData[i])
      }
      if (this.onDeployFinish) {
        this.onDeployFinish()
      }
      this.$emit('exitDeploy')
    }
  }

  /**
   * 处理部署任务
   * @param reqest
   */
  processDeploy (reqest: DeployRequest[]) {
    const that = this
    return new Promise((resolve, reject) => {
      deployRequestService.requestDeploy(reqest, {
        onMessage (taskMessage) {
          that.xterm?.addMessage(taskMessage.message)
          if (that.options.onMessage) {
            that.options.onMessage(taskMessage)
          }
        },
        onError (err) {
          that.xterm?.addLineMessage('任务发生错误:' + err.message)
          if (that.options.onError) {
            that.options.onError(err)
          }
        },
        onOpen () {
          that.xterm?.addLineMessage('在podman启动部署任务')
          if (that.options.onOpen) {
            that.options.onOpen()
          }
        },
        onFinish () {
          that.xterm?.addLineMessage('任务结束')
          if (that.options.onFinish) {
            that.options.onFinish()
          }
        }
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        this.$Message.error(err.message)
        if (this.onDeployError) {
          this.onDeployError(err)
        }
        that.$emit('exitDeploy')
        reject(err)
      })
    })
  }
}
