
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PropType } from 'vue'
import CloudBaseResourceApi, { CloudBaseResource } from '@/api/maintain/cloud-resource/cloud-base-resource'

@Component({
  name: 'FollowUpWork'
})
export default class extends Vue {
  @Prop({
    type: Object as PropType<CloudBaseResource>,
    required: true
  })
  readonly cloudBaseResourceData!: CloudBaseResource

  @Prop({
    type: Object as PropType<Record<string, string>>,
    required: false,
    default: undefined
  })
  readonly params?: Record<string, string>

  loading = false

  go () {
    this.loading = true
    CloudBaseResourceApi.getItemById(this.cloudBaseResourceData.id!).then((response) => {
      this.loading = false
      this.$router.push({
        path: this.cloudBaseResourceData.extJobUri as string,
        query: this.params
      })
    })
  }

  finish () {
    const path = this.$route.params.from
    this.$router.push(path)
  }

  back () {
    this.$emit('back', 2)
  }
}
