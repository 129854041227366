
import { AppInstance } from '@/api/maintain/app-instance'
import { BaseModal } from '@/libs/modal-helper'
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SelectComplieInstanceModal'
})
export default class SelectComplieInstanceModal extends Vue implements BaseModal {
    /** 当前选中部署的实例列表 */
    @Prop({
      type: Array as PropType<AppInstance[]>,
      default: () => []
    })
    appInstances!: AppInstance[]

    showModal=false

    /** 编译实例id */
    complieInstanceId=this.appInstances[0]?.id || -1

    show (): void {
      this.showModal = true
    }

    close (): void {
      this.showModal = false
    }

    doSave (): void {
      this.$emit('onOk', this.complieInstanceId)
    }

    doCancel (): void {
      this.$emit('onCancel')
    }
}
