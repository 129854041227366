import { Component, Provide, Vue } from 'vue-property-decorator'
import RabbitmqTool from '@/libs/rabbitmq'
import shortUuid from 'short-uuid'

/** 并发连接数(与后端保持一致) */
const PARALLEL_COUNT = 5 as const
/** 交换机Url */
const EXCHANGE_URL = process.env.VUE_APP_Rabbitmq_ExchangeUrl
/** 数据储存Key */
const DATA_SET_KEY = 'applicationEventData' as const
/** 订阅主题名称 */
const TOPIC_NAME = 'xbgcodingApplication' as const

/** 对象类型 */
export enum SourceType {
  /** 客户端 */
  container
}

/** 原始消息类型 */
export interface RawSubscribeMessage {
  /** 团队id */
  tid: string;
  /** 对象类型 */
  ot: SourceType;
  /** 对象ID */
  oid: string;
  /** 上行时间戳 */
  t: number;
}

/** 数据集标签 */
export type SetKey = string

export type MaskedSetKey = string

/** 数据标签蒙版 */
export type SetMask = keyof RawSubscribeMessage

/** 数据集store */
export interface SetData {
  teamId: string;
  objectType: SourceType;
  objectId: string;
  time: number;
}

/** 订阅号类型 */
export type SubscribeCode<T> = T // | '*'

/** 订阅信息 */
export interface SubscribeInfo {
  /** 团队ID */
  teamId?: SubscribeCode<string>;
  /** 对象类型 */
  objectType?: SubscribeCode<SourceType>;
  /** 对象ID */
  objectId?: SubscribeCode<string>;
}

export interface SubscribeEventOption extends SubscribeInfo {
  eventName: MaskedSetKey;
}

/** 订阅配置 */
export interface SubscribeOptions extends SubscribeInfo {
  /** 开启并行 */
  parallel?: boolean;
  /**
   * 回调函数
   * @description 回调函数参数为原始消息,如果你想获取格式化后的消息请从vuex中获取
   */
  callback?: (data: RawSubscribeMessage) => void;
  /**
   * 启动时加载实时数据
   * 如果为true,则会在启动、激活时自动获取实时数据,如果为false,则不会加载;如果是对象,则使用该对象在启动、激活时自动获取实时数据
   * @deprecated
   */
  loadInitData?: boolean | Record<string, any>;
  /**
   * 开启调试
   */
  debug?: boolean;
  /**
   * 连接时重置所有订阅
   */
  resetOnConnect?: boolean;
}

/** 注册事件 */
export interface RegisterEvent {
  reg: RegExp;
  count: number;
  name: MaskedSetKey;
}

/**
 * 电梯rabbitmq连接创建与管理混合器
 */
@Component
export default class ElevatorRabbitmq extends Vue {
  /** 连接列表 */
  connectList: RabbitmqTool[] = []
  /** 数据集 */
  dataSet: Record<SetKey, SetData> = {}

  /** 注册事件列表 */
  registerEventList: RegisterEvent[] = []

  /** 加载实时数据方法 */
  loadRealtimeData = () => { /** */ }

  /** 数据集标签前缀 */
  readonly DATA_SET_KEY_PREFIX = DATA_SET_KEY + '_' + shortUuid.generate()

  /**
   * 创建连接
   * @param callback 数据回调
   * @param option 订阅配置
   *
   */
  async connent (option: SubscribeOptions = { loadInitData: true, debug: false, resetOnConnect: false }): Promise<void> {
    const queueList: string[] = []
    await this.clearConnect()
    if (option.resetOnConnect) { this.unregisterEventAll() }
    // this.$set(this, 'dataSet', {})
    if (option.parallel) {
      for (let i = 0; i < PARALLEL_COUNT; i++) {
        queueList.push(this.getQueueName(option, i))
      }
    } else {
      queueList.push(this.getQueueName(option))
    }
    await Promise.all(queueList.map(queueName => {
      const rbmq = new RabbitmqTool(true, option.debug)
      this.connectList.push(rbmq)
      return rbmq.init(queueName, (data: RawSubscribeMessage) => {
        // this.messageHandler(data)
        // console.log('rabbitmq -> vue(%s)', moment(data.t).format('YYYY-MM-DD HH:mm:ss.SSS'), data)
        this.emitEvent(data)
        option.callback?.(data)
      })
    }))

    // 加载初始数据(mongodb -> vue)
    if (option.loadInitData === undefined || option.loadInitData) {
      this.loadRealtimeData = () => { /** */ }
      console.warn('unsupport loadInitData')
    } else {
      this.loadRealtimeData = () => { /** */ }
    }
  }

  /**
   * 获取订阅主题
   * @param option 订阅配置
   * @param connentIndex 连接索引
   */
  private getQueueName (option: SubscribeOptions, connentIndex?: number): string {
    const { teamId, objectType, objectId } = option
    return `${EXCHANGE_URL}${TOPIC_NAME}.${teamId ?? '*'}.${objectType ?? '*'}.${objectId ?? '*'}.${connentIndex ?? '*'}`
  }

  beforeDestroy () {
    this.connectList.forEach(rbmq => rbmq.disconnection())
  }

  deactivated () {
    this.connectList.forEach(rbmq => rbmq.disconnection())
    // this.saveToDisk()
  }

  activated () {
    this.connectList.forEach(rbmq => rbmq.restoreConnect())
    // this.restoreFromDisk()
    this.loadRealtimeData?.()
  }

  /**
   * 断开连接
   */
  async disconnect (): Promise<void> {
    console.log('断开连接')
    await Promise.all(this.connectList.map(rbmq => rbmq.disconnection()))
  }

  /**
   * 清空连接
   */
  async clearConnect (): Promise<void> {
    await this.disconnect()
    this.connectList = []
  }

  /**
   * 消息处理器
   * @param data 消息
   */
  private messageHandler (data: RawSubscribeMessage) {
    this.$set(this.dataSet, this.messageToSetKey(data), this.messageToSetData(data))
  }

  /**
   * 将原始消息映射为状态数据
   * @param raw 原始消息
   * @returns
   */
  private messageToSetData (raw: RawSubscribeMessage): SetData {
    return {
      objectId: raw.oid,
      objectType: raw.ot,
      teamId: raw.tid,
      time: raw.t
    }
  }

  /**
   * 将原始消息转换为状态键
   * @param raw 原始消息
   * @returns
   */
  messageToSetKey (raw: RawSubscribeMessage): SetKey {
    return `${raw.tid}.${raw.ot}.${raw.oid}` as SetKey
  }

  /**
   * 将数据集数据保存到硬盘释放内存
   */
  saveToDisk (): void {
    const data = JSON.stringify(this.dataSet)
    window.sessionStorage.setItem(this.DATA_SET_KEY_PREFIX, data)
    this.$set(this, 'dataSet', {})
  }

  /**
   * 将数据集数据从硬盘中恢复
   */
  restoreFromDisk (): void {
    const data = window.sessionStorage.getItem(this.DATA_SET_KEY_PREFIX)
    window.sessionStorage.removeItem(this.DATA_SET_KEY_PREFIX)
    if (data) {
      this.$set(this, 'dataSet', JSON.parse(data))
    }
  }

  /**
   * 提交事件
   * @param raw 原始消息
   */
  emitEvent (raw: RawSubscribeMessage): void {
    const key = this.messageToSetKey(raw)
    const data = this.messageToSetData(raw)
    this.registerEventList.forEach(e => {
      if (e.reg.test(key)) {
        this.$bus.$emit(e.name, data)
      }
    })
  }

  /**
   * 生成正则表达式
   * @param option
   * @returns
   */
  private mapSubscribeInfoToRegExp (option: SubscribeInfo): RegExp {
    const defaultReg = {
      string: '[a-zA-Z0-9_-]+',
      number: '[0-9]+'
    }
    return new RegExp(`^${option.teamId ?? defaultReg.string}\\.${option.objectType ?? defaultReg.number}\\.${option.objectId ?? defaultReg.string}$`)
  }

  /**
   * 注册事件
   */
  @Provide()
  registerEvent (option: SubscribeEventOption): void {
    // console.log('register', option)
    const index = this.registerEventList.findIndex(e => e.name === option.eventName)
    // 重复注册则只添加对应的计数
    if (~index) {
      this.registerEventList[index].count++
    } else {
      this.registerEventList.push({
        name: option.eventName,
        reg: this.mapSubscribeInfoToRegExp(option),
        count: 1
      })
    }
  }

  /**
   * 注销事件
   */
  @Provide()
  unregisterEvent (option: SubscribeEventOption): void {
    // console.log('unregisterEvent', option)
    const index = this.registerEventList.findIndex(e => e.name === option.eventName)
    if (~index && this.registerEventList[index].count > 1) {
      this.registerEventList[index].count--
    } else {
      this.registerEventList.splice(index, 1)
    }
  }

  /**
   * 注销全部事件
   */
  unregisterEventAll (): void {
    this.registerEventList = []
  }
}
