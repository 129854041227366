/**
 * 应用
 */

import DefaultApiService, { BaseHasCreatorAndTimeEntity } from '@/libs/api-request'
import { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'

/**
 * 应用接口
 */
export interface Application extends BaseHasCreatorAndTimeEntity {
  /** 应用名称 */
  name: string;
  /** 应用标题 */
  title?: string;
  /** 应用uuid */
  appUuid?: string;
  /** git地址 */
  gitUrl?: string;
  /** git账号 */
  gitUser?: string;
  /** git密码 */
  gitPwd?: string;
  /** git分支 */
  gitBranch?: string;
  /** git标签 */
  gitTag?: string;
  /** 应用类型 */
  appType?: ApplicationType;
  /** 应用类型的名称 */
  appTypeName?: string;
  /** 基础资源id,如果不为空表示本应用关联基础云资源 */
  cloudResourceId?: number;
  /** 应用描述 */
  appMemo?: string;
  /** 归属团队id */
  teamId?: number;
  /** 以,号分隔的标签列表 */
  tags?: string;
  /** 最近编译成功时间 */
  lastCompileTime?: string;
  /** 最近编译成功的结果文件地址 */
  lastCompileResultUrl?: string;
  /** 镜像id,当为镜像应用时，不为空 */
  imageId?: number;
  /** 镜像名称：可为镜像资源的镜像名称，也可是从镜像中心拉取的镜像名称 */
  imageName?: string;
  /** 应用id，当appType为我们系统生成的类型时，不为空 */
  projectId?: number;
  /** 项目标题 */
  projectTitle?: string;
  /** 项目名称 */
  projectName?: string;
  /** 项目组ID */
  projectGroupId?: number;
  /** 项目组名称 */
  projectGroupName?: string;
  /** 云资源名称 */
  cloudResourceName?: string;
  /** 镜像类型 */
  imageType?: number;
  /** 打包镜像时使用的dockerfile附件路径 */
  buildDockfileUrl?: string;
  /** 打包挂载目录, 通过','切割 */
  buildMountDirs?: string;
  /** 当前用户可以操作 */
  canOperation?: boolean;
  /** 当前用户可以查看 */
  canView?: boolean;
  /** 默认值配置文件名，如果为空，则存贮在数据库中,配置模板名:文件名的格式,例：default:文件名 */
  defaultConfigFile?: string;
 /** 配置模板提示，配置模板名:提示组件,例:default:提示组件 */
  defaultConfigAlerts?: string;
}

export enum ApplicationType {
  WebApi = 1,
  WebFront,
  NotStandardApp,
  ImageFromSystem,
  ImageFromHub,
  NotStandardWebFront
}

export interface CreateNewStandAppRequest {
  /// 应用标题
  appTitle?: string;
  /// 应用名称
  appName?: string;
  /// 对应的项目id
  projectId?: number;
  /// 应用对应的git分支
  gitBranch?: string;
  /// 应用对应的git标签
  gitTag?: string;
  /// 给应用设置的tag列表，逗号分开
  appTags?: string;
  /// 项目组id
  projectGroupId?: number;
  /// git地址
  gitUrl?: string;
  /// git用户名
  gitName?: string;
  /// git密码
  gitPwd?: string;
  /// 团队资源id
  teamResourceId?: number;
}

export interface CreateNewImageAppRequest {
  /// 应用名称
  appName?: string;
  /// 应用标题
  appTitle?: string;
  /// 镜像id
  imageId?: number;
  /// 镜像名称
  imageName?: string;
  /// 给应用设置的tag列表，逗号分开
  appTags?: string;
  /// 团队资源id
  teamResourceId?: number;
 }

export interface CreateNewNoStandeAppRequest {
   /** 应用名称 */
   appName?: string;
   /** 应用标题 */
   appTitle?: string;
   /** 对应的项目代码 */
   appCode?: string;
   /** git地址 */
   gitUrl?: string;
   /** git用户名 */
   gitUser?: string;
   /** git密码 */
   gitPwd?: string;
   /** 应用对应的git分支 */
   gitBranch?: string;
   /** 应用对应的git标签 */
   gitTag?: string;
   /** 给应用设置的tag列表，逗号分开 */
   appTags?: string;
   /** 是否附加到nginx服务，用于前端打包 */
   wantAttNginx?: boolean;
   /** 是否要进行打包 */
   wantBuild?: boolean;
   /** 打包的dockerfile数据 */
   buildDockfileUrl?: string;
   /** 打包挂载目录 */
   buildMountDirs?: string;
   /** 使用的运行版本 */
   operationVersion?: string;
   /** 团队资源id */
   teamResourceId?: number;
 }

/**
 * 应用服务请求api接口
 */
class ApplicationApi extends DefaultApiService<Application> {
  /**
   * 取得当前团队的应用列表
   * @returns
   */
  getCurTeamApplicationList () {
    return this.requestList('curTeamApplicationList', null, 'GET')
  }

  /**
   * 取得当前团队除了前端类型外的所有应用
   * @returns
   */
  getCurTeamApplicationListExceptWeb () {
    return this.requestList('curTeamApplicationListExceptWeb', null, 'GET')
  }

  /** 查询有权限时，可查看所有应用 */
  hasPermission () {
    return this.requestList('hasPermission', undefined, 'GET')
  }

  /**
   * 删除实例
   * @param appInstanceId
   * @returns
   */
  deleteApplication (applicationId: number) {
    return this.requestList(`/${applicationId}`, null, 'DELETE')
  }

  /** 更新标准应用 */
  updateStandApp (data: Application) {
    return this.request('updateStandApp', data, 'POST')
  }

  /** 更新非标准应用 */
  updateNoStandApp (data: Application) {
    return this.request('updateNoStandApp', data, 'POST')
  }

  /** 更新镜像应用 */
  updateImageApp (data: Application) {
    return this.request('updateImageApp', data, 'POST')
  }

  /** 新增标准应用 */
  addNewStandApp (data: CreateNewStandAppRequest) {
    return this.request('addNewStandApp', data, 'POST')
  }

  /** 新增镜像应用 */
  addNewImageApp (data: CreateNewImageAppRequest) {
    return this.request('addNewImageApp', data, 'POST')
  }

  /** 新增非标准应用 */
  addNewNoStandeApp (data: CreateNewNoStandeAppRequest) {
    return this.request('addNewNoStandeApp', data, 'POST')
  }

  public queryBackendApps (query: PagerQueryBean): Promise<PageDataApiResult<Application>> {
    return this.queryByQueryBean('queryBackendApps', query)
  }

  /**
   * 更新编译结果
   * @param applicationId
   * @param appInstanceId
   * @returns
   */
  public updateCompileResult (applicationId: number, url: string) {
    return this.requestList(`/updateCompileResult/${applicationId}?url=${url}`, null, 'POST')
  }

  /**
    * 取得id对应的对象的值
    * @param id 要删除记录的主键id
    */
  public getItemById (id: number): Promise<DataApiResult<Application>> {
    return this.request(`${id}`, undefined, 'GET')
  }

  /**
   * 修改应用git密码
   * @param id
   * @param gitPwd
   * @returns
   */
  public updateGitPwd (id: number, gitPwd: string) {
    return this.request('updateGitPwd', {
      id: id,
      newPwd: gitPwd
    }, 'POST')
  }

  constructor () {
    super('/api/maintain/application')
  }
}

export default new ApplicationApi()
