import { render, staticRenderFns } from "./resource-introduce.vue?vue&type=template&id=c3d7f86e&scoped=true&"
import script from "./resource-introduce.vue?vue&type=script&lang=ts&"
export * from "./resource-introduce.vue?vue&type=script&lang=ts&"
import style0 from "./resource-introduce.vue?vue&type=style&index=0&id=c3d7f86e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d7f86e",
  null
  
)

export default component.exports