import { PageInfo } from '@/libs/api-request'
import { PageDataApiResult, PageListData, PagerQueryBean } from '@/libs/http-request'
import { Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { KEY_OPTION_PARAM, KEY_FORMMODE, EXTRA_DATA } from './data-form-page'
import { expect } from 'chai'
import cryptoTool from '@/libs/crypto-tool'
import { FormState } from '@/libs/form-state'
import { getShortUuid } from '@/libs/uuid'
/**
 * 当前页设置器
 */
export interface CurPageSetter{
    /**
       * 设置当前页号
       */
      setCurPage (page: number): void;
  }
/// 工具栏导出全部数据
export const ToolBarCodeExportAll = 'export-all'
/**
 * 表格页面的基础类
 */
export default abstract class BaseTablePanel<T> extends Vue {
  /// 数据加载中
  protected abstract loading: boolean

  /**
   * 页面查询数据
   */
  protected abstract queryData: PagerQueryBean

  /**
   * 页面数据
   */
  protected abstract pageData: PageListData<T>

  /**
   * 从服务器拉取数据
   * @param pagerQueryBean 要拉取的页面查询数据
   */
  protected abstract onPullDataListFromServer(pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<T>>;

  /**
    * 拉取指定页的数据
  */
  protected pullDataList (pageInfo: PageInfo|undefined): Promise<PageListData<T>> {
    this.loading = true
    if (!pageInfo) {
      pageInfo = {
        pageSize: this.queryData.pageSize,
        curPage: 1
      }
    }
    // 清空原来的数据
    this.pageData.items = []
    this.queryData.page = pageInfo.curPage!
    this.queryData.pageSize = pageInfo.pageSize!

    const that = this

    return new Promise<PageListData<T>>((resolve, reject) => {
      that.onPullDataListFromServer(this.queryData)
        .then(response => {
          resolve(response.data!)
        })
        .catch((err: Error) => {
          this.$Notice.error({
            title: '发生错误',
            desc: '获取页面数据失败:' + err.message
          })
          reject(err)
        })
        .finally(() => {
          that.loading = false
        })
    })
  }

  /**
   * 工具条按钮事件
   * @param code
   */
  protected toolBarButtonAction (code: string, table: any) {
    if (ToolBarCodeExportAll === code) {
      this.exportAlData(table)
    }
  }

  /**
   * 执行所有数据导出操作
   * @param 表格对象
   */
  protected exportAlData (table: any) {
    // 分页把数据下载

    const list: Array<T> = []
    const queryBean = _.cloneDeep(this.queryData)
    queryBean.page = 1
    queryBean.pageSize = 50
    this.pullPageDataFromServer(queryBean, list, () => {
      table.openExport({
        // 默认选中类型
        type: 'xlsx',
        // 自定义类型
        data: list
      })
    })
  }

  /**
   * 从服务器取得一页数据
   * @param pagerQueryBean 查询bean
   * @param data 结果存放的数据列表
   * @param finish  完成回调事件
   */
  private pullPageDataFromServer (pagerQueryBean: PagerQueryBean, data: Array<T>, finish: () => void) {
    this.onPullDataListFromServer(pagerQueryBean)
      .then((response) => {
        const items = response.data!.items
        if (items.length > 0) {
          data.push(...items)
          pagerQueryBean.page += 1
          this.pullPageDataFromServer(pagerQueryBean, data, finish)
        } else {
          finish()
        }
      })
  }

  /**
   * 打开表单对象
   * @param url 表单路径
   * @param mode 显示模式
   */
  openFormPage (name: string, val?: any, mode: string = FormState.View, tagName?: string, extraData?: string) {
    expect(name, '参数name不允许为空').not.undefined

    const route = {
      name: name,
      query: {
        query: getShortUuid()
      },
      params: {
        [KEY_FORMMODE]: mode,
        [KEY_OPTION_PARAM]: val,
        [EXTRA_DATA]: extraData || ''
      }
    }
    if (tagName) {
      (route.params as any).tagName = tagName
    }
    this.$router.push(route)
  }

  /**
   * 当前页面开始加载
   */
  protected startLoading () {
    this.loading = true
  }

  /**
   * 当前页面加载完成
   */
  protected finishLoading () {
    this.loading = false
  }
}
