
import { Prop, Vue, Component, Model } from 'vue-property-decorator'
import TeamResourceApi from '@/api/maintain/resource-management/team-resource'

// 团队选择组件
@Component({ name: 'TeamResourceSelector', components: {}, mixins: [] })
export default class TeamResourceSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number | string;

  curValue = this.value || 0;

  @Prop({
    type: String,
    default: '请选择团队资源'
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;

  loading = false;

  datas: Array<any> = [];

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData () {
    this.$emit('input', undefined)
    this.curValue = -1

    this.loadDataFromServer()
  }

  mounted () {
    this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    TeamResourceApi.queryCanAddAppItems().then((res) => {
      this.datas = res.data?.map((e) => {
        return {
          label: e.name,
          value: e.id
        }
      }) || []
      this.loading = false
    })
  }
}
