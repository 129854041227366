import cryptoTool from '@/libs/crypto-tool'
import { expect } from 'chai'
import { Vue } from 'vue-property-decorator'
import storage from '@/libs/local-storage'

/// 表单数据的id的key
export const KEY_OPTION_PARAM = 'optionParam'
export const KEY_FORMMODE = 'mode'
export const EXTRA_DATA = 'extraData'

/**
 * 数据表单页基础类，提供表单页面的的基础操作
 * T 对应的实体对象类型
 */
export abstract class DataFormPage<T> extends Vue {
  /**
   * 表单数据
   */
  protected abstract formData: T

  /**
   * 表单原始数据
   */
  protected rawData?: T

  /**
   * 加载表单数据操作
   * @param id
   */
  protected abstract onLoadFormData(id: number, extData?: string): Promise<T>;

  /**
   * 创建新增表单数据
   */
  protected abstract onCreateNewFormData(extData?: string): Promise<T>;

  /**
   * 新增新增数据到服务器
   */
  protected abstract onInsertFormData(): Promise<T>;

  /**
   * 保存修改的数据到服务器
   */
  protected abstract onSaveFormData(): Promise<T>;

  /**
   * 从服务器加载数据
   */
  protected onLoadFormDataFromServer (): Promise<T> {
    const id = this.getCurPageDataId()
    const extData = this.getCurExtraData()
    expect(id, '当前表单没有提供id参数').not.undefined
    return new Promise<T>((resolve, reject) => {
      this.onLoadFormData(id, extData)
        .then(data => {
          this.formData = data
          this.rawData = data
          this.setCurPageDataId(id)
          this.setCurExtraData(extData)
          resolve(data)
        }).catch((err: Error) => {
          console.error(`加载id=${id}数据时出错,%o`, err)
          reject(err)
        })
    })
  }

  /**
   * 新增表单数据操作
   */
  protected onNewFormData (): Promise<T> {
    const extData = this.getCurExtraData()
    this.setCurExtraData(extData)
    return new Promise<T>((resolve, reject) => {
      this.onCreateNewFormData(extData)
        .then(data => {
          this.formData = data
          resolve(data)
        })
        .catch((err) => {
          console.error('创建新的表单数据时出错,%o', err)
          reject(err)
        })
    })
  }

  /**
   * 取得当前表单的记录Id 选在路由参数中找，找不到，则从上一次的记录中找
   */
  protected getCurPageDataId (): any {
    const id = this.$route.params[KEY_OPTION_PARAM]
    if (id) return id
    return storage.loadCurPageId()
  }

  /**
   * 保存当前页面的id
   * @param id
   */
  protected setCurPageDataId (id: any) {
    storage.saveCurPageId(id)
  }

  /** 保存当前页额外数据 */
  protected setCurExtraData (extraData: string) {
    storage.setCurExtraData(extraData)
  }

  /** 获取当前页额外数据 */
  protected getCurExtraData (): any {
    const data = this.$route.params[EXTRA_DATA]
    if (data) return data
    return storage.loadCurExtraData()
  }
}
