
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ApplicationApi, { Application } from '@/api/maintain/application'
import showAddModal from '@/views/maintain/cloud-resource/components/show-add-app-by-cloud-modal'

/**
 * 字段选择器
 */
@Component({
  name: 'QueryAppSelelctor'
})
export default class extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  readonly cloudId!: number

  /** 当没有应用数据时，指定增加的镜像资源Id */
  @Prop({
    type: Number,
    required: true
  })
  readonly imageId!: number

  /** 是否拥有增加应用权限 */
  @Prop({
    type: Boolean,
    default: true
  })
  readonly canAddApp!: boolean

  /** 加载完成 */
  loading = false
  apps: Application[] = []

  @Watch('cloudId')
  changeCloudId () {
    if (this.cloudId) { this.load() }
  }

  load () {
    this.loading = true
    ApplicationApi.query({ page: 1, pageSize: 100, sorts: [], cloudResourceId: this.cloudId }).then((res) => {
      this.$Message.success('应用列表查询成功')
      this.apps = res.data?.items || []
      this.loading = false
    }).catch(err => {
      this.apps = []
      this.$Message.error('应用列表查询成功: ' + err)
      this.loading = false
    })
  }

  showAddModal () {
    showAddModal(this.imageId).then((id: number) => {
      this.loading = true
      ApplicationApi.query({ page: 1, pageSize: 100, sorts: [], cloudResourceId: this.cloudId })
        .then((res) => {
          this.$Message.success('应用列表查询成功')
          this.apps = res.data?.items || []
          this.loading = false

          this.$emit('create', id)
        })
        .catch(err => {
          this.apps = []
          this.$Message.error('应用列表查询成功: ' + err)
          this.loading = false
        })
    })
  }
}
